<template>
  <div class="router-list">
    <div class="back" :style="{ top: backtop, right: backright }">
      <img :src="require('../assets/imgs/house.png')" class="backhouse" />
      <router-link :to="{ path: homeBack }" class="backhome"
        >{{ homeName }} ></router-link
      >
      <div class="backname">{{ name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header",
  props: {
    name: String,
    // homeBack: String,
    homeName: String,
    required: true,
    backtop: {
      type: String,
      default: "",
    },
    backright: {
      type: String,
      default: "",
    },
    homeBack: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: "",
    };
  },
  watch: {},
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.back {
  display: flex;
  height: 50px;
  align-items: center;
  line-height: 50px;
  font-size: 14px;
  position: absolute;
  .backhouse {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  .backhome {
    color: #a7a7a7;
  }
  .backname {
    margin-left: 12px;
    color: #585858;
  }
}
</style>