<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-left">
        <div class="qrCode-box">
          <p v-if="!isEn">关注我们</p>
          <p v-if="isEn">Pay attention to our</p>
          <img :src="baseUrl + ercodePath" alt="">
        </div>
      </div>
      <div class="footer-center"></div>
      <div class="footer-right">
        <div class="right-contact">
          <p>联系</p>
          CONTACT
        </div>
        <div class="right-infos">
          <div>
            <span
              :class="{ active: activeTab === ind }"
              v-for="(val, ind) in tabList"
              @click="activeTabclick(val, ind)"
              :key="ind"
              >{{ val.cityName }}</span
            >
          </div>
          <div>{{ companyName }}</div>
          <ul>
            <li v-for="(val, ind) in infos" :key="ind">
              <img :src="val.img" alt="" />
              <span>{{ val.text }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-bottom" v-if="!isEn">康存数据服务（上海）有限公司   COPYRIGHT © 2015 版权所有
      <img src="../assets/imgs/homepage/national-emblem.png" alt="">
      沪ICP备15012538号-2</div>
    <div class="footer-bottom" v-if="isEn">Copyright © 2015 Comtree Data Services (Shanghai) Co., Ltd. <img src="../assets/imgs/homepage/national-emblem.png" alt="">All Rights Reserved</div>
  </div>
</template>

<script>
import { companyList,selectMaterialList } from "@/api/common.js";
import { baseURL } from "@/api/config.js";

export default {
  name: "Footer",
  data() {
    return {
      tabList:[],
      activeTab:0,
      companyName: '',
      isEn: sessionStorage.getItem("langType") === 'en-us' ? true : false,
      infos:[
        {img: require("@/assets/imgs/common/icon-1.png"),text:'400-652-1335'},
        {img: require("@/assets/imgs/common/icon-2.png"),text:'201900'},
        {img: require("@/assets/imgs/common/icon-3.png"),text:'www.comtree.cn'},
        {img: require("@/assets/imgs/common/icon-4.png"),text:'021-65988941'},
        {img: require("@/assets/imgs/common/icon-5.png"),text:'中国上海市宝山区双城路803号9号楼16层（宝莲城）  '},
        {img: require("@/assets/imgs/common/icon-6.png"),text:'webmaster@900sui.com'},
      ],
      baseUrl: baseURL,
      ercodePath: "",
    }
  },
  created() {
    this.getCompanyList();
    this.getErcode();
  },
  methods: {
    activeTabclick(val, ind) {
      this.activeTab = ind;
      this.companyName = val.companyName;
      this.infos[0].text = val.telephone;
      this.infos[1].text = val.postCode;
      this.infos[2].text = val.webAddress;
      this.infos[3].text = val.fax;
      this.infos[4].text = val.companyAddress;
      this.infos[5].text = val.email;
    },
    getCompanyList() {
      companyList({
        lang: sessionStorage.getItem("langType"),
      }).then((res) => {
        if (res.data.length) {
          this.tabList = res.data;
          this.companyName = this.tabList[0].companyName;
          this.infos[0].text = this.tabList[0].telephone;
          this.infos[1].text = this.tabList[0].postCode;
          this.infos[2].text = this.tabList[0].webAddress;
          this.infos[3].text = this.tabList[0].fax;
          this.infos[4].text = this.tabList[0].companyAddress;
          this.infos[5].text = this.tabList[0].email;
        }
      });
    },
    getErcode(){
      selectMaterialList({
        groupName:"footer二维码",
        lang: sessionStorage.getItem("langType"),
      }).then((res)=>{
        console.log(res)
        if(res.rows.length){
          this.ercodePath = res.rows[0].savePath;
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.footer{
  min-height: 300px;
  background: #303030;
  color: #979797;
  width: 100%;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-left {
      height: 230px;
      width: 470px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .qrCode-box {
        text-align: center;
        margin-right: 90px;
        & > p {
          font-size: 16px;
          line-height: 1;
          margin-bottom: 18px;
        }
      }
    }
    .footer-center {
      width: 1px;
      height: 100px;
      background: #424242;
    }
    .footer-right{
      min-height: 230px;
      padding: 30px 0;
      flex: 1;
      display: flex;
      justify-content: space-between;
      .right-contact{
        margin-left: 90px;
        margin-right: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > p {
          font-size: 24px;
          line-height: 1;
          margin-bottom: 18px;
        }
      }
      .right-infos {
        width: calc(100% - 68px);
        line-height: 1;
        & > div {
          margin-bottom: 14px;
          font-size: 16px;
          display: flex;
          & > span {
            margin-right: 30px;
            cursor: pointer;
            display: block;
            &:hover,
            &.active {
              &::after {
                margin-top: 4px;
                content: "";
                display: block;
                //width: 100%;
                background: #979797;
                height: 2px;
              }
              //text-decoration: underline;
            }
          }
        }
        & > ul {
          display: flex;
          flex-wrap: wrap;

          li {
            padding-right: 20px;
            line-height: 1.5;
            width: 50%;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            & > img {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    width: calc(100% - 200px);
    margin: 0 auto;
    height: 70px;
    border-top: 1px solid #424242;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      margin-left: 132px;
      margin-right: 6px;
      width: 15px;
      height: 15px;
    }
  }
}
</style>
