<template>
  <div class="router-wrap">
    <div class="development">
      <div class="profilejianjiebox">
        <img class="profilejianjie" :src="jianjieback" />
        <div
          class="profilejianjiewenzi"
          :style="{ color: langTrue == 'en-us' ? '#e7e7e7' : '#444444' }"
        >
          大事记
        </div>
      </div>
      <div class="developyear" v-if="data.length !== 0">
        <div
          class="developyearchildleft"
          v-show="indexmax >= 8"
          @click="leftClick()"
        ></div>
        <div
          class="developyearchildright"
          v-show="indexmax >= 8"
          @click="rightClick()"
        ></div>

        <div class="developyearchildleft" v-show="indexmax < 8"></div>
        <div class="developyearchildright" v-show="indexmax < 8"></div>
        <div class="developyearchildline"></div>
        <div class="developyearval">
          <div
            class="developyearvalchild"
            :style="{
              width: indexmax * 150 + 'px',
              left: this.index * 150 + 'px',
            }"
            ref="developyearvalbox"
          >
            <div
              class="developyearchild"
              v-for="(item, ind) in data"
              :key="ind"
              @click="highlightClick(item, ind)"
            >
              <!-- <img
                class="developyearchildurl"
                :src="item.url"
                v-if="highlightindex !== ind"
              /> -->
              <!-- <div class="developyearchildname" v-if="highlightindex !== ind">
                {{ item.name }}
              </div> -->
              <div
                class="developyearchildair"
                v-if="highlightindex !== ind"
              ></div>
              <div class="developyearchildyear" v-if="highlightindex !== ind">
                {{ item.year }}
              </div>

              <img
                class="developyearchildback"
                :src="item.backurl"
                v-if="highlightindex == ind"
              />
              <!-- <img
                class="developyearchildnewurl"
                :src="item.newurl"
                v-if="highlightindex == ind"
              /> -->
              <!-- <div class="developyearchildnewname" v-if="highlightindex == ind">
                {{ item.name }}
              </div> -->
              <div class="developyearchildnewyear" v-if="highlightindex == ind">
                {{ item.year }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="developval" v-if="data.length !== 0">
        <div
          class="deveurl"
          :style="{
            height:
              dataval.length <= 1
                ? 0
                : (dataval.length - 1) * 50 + number2 + 'px',
          }"
        ></div>

        <div
          class="developvalchild"
          v-for="(item, ind) in dataval"
          :key="ind"
          ref="developvalchild"
        >
          <div class="deveair" v-if="developoverind !== ind"></div>
          <img
            class="deveairhigh"
            v-if="developoverind == ind"
            :src="dashijihighmonth"
          />
          <div class="developtime">
            {{ item.occurrenceMonth.slice(5, 7) }}月
          </div>
          <div
            class="developvalue"
            :class="{ newdevelopvalue: developoverind == ind }"
            @mouseover="developover(ind)"
            @mouseout="developout"
          >
            <!-- v-html="item.description" -->
            <p v-for="val in item.descriptionGroupByMonth" :key="val + ind">
              <span class="inddevelopair">·</span>
              {{ val }}
              <!-- {{ item.description }} -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mentlist } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
export default {
  name: "BusinessDetails",
  data() {
    return {
      index: 0, //点击左右按钮所需下标    (数组长度 - 8) 转为负值
      indexmax: 0, //数组长度
      highlightindex: 0, //点击高亮
      number: [],
      number2: null,
      data: [
        //  {
        //   name: "双核发展",
        //   year: 2022,
        //   url: require("../assets/imgs/aboutkangcun/developnight.png"),
        //   newurl: require("../assets/imgs/aboutkangcun/developnewnight.png"),
        //   backurl: require("../assets/imgs/aboutkangcun/develophighlight.png"),
        //   title:
        //     "2015年   顺应国家产业导向，康存形成“互联网+大健康产业”双核发展格局。",
        //   val: [
        //     {
        //       occurrenceTime: "8yue",
        //       description:
        //         "与长安保险宁波分公司签署战略合作协议。\n与中国民生银行宁波分行签署乐付保合作协议。  设立宁波子公司——康存网络科技（宁波",
        //     },
        //   ],
        // },
      ],
      dataval: [
        {
          occurrenceTime: "",
          description: "",
        },
      ],
      datatitle: "",
      developoverind: null,

      dashijihighmonth: require("../assets/imgs/aboutkangcun/dashijihighmonth.png"),

      langTrue: sessionStorage.getItem("langType"),
      jianjieback:
        sessionStorage.getItem("langType") == "en-us"
          ? require("../assets/imgs/aboutkangcun/memorabilia.png")
          : require("../assets/imgs/aboutkangcun/memorabilia.jpg"),

      // next: 1,
      // prev: 0,
    };
  },
  computed: {
    costRequest() {
      return {
        newsType: this.activeTab,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.add();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  mounted() {
    this.getmentlist();
    // setTimeout(() => {
      // this.add();
    // }, 300);
  },

  components: {},
  methods: {
    leftClick: function () {
      let boxleft = parseInt(this.$refs.developyearvalbox.style.left);
      this.index++;
      if (boxleft >= 0) {
        this.$refs.developyearvalbox.style.left = "0px";
        this.index = 0;
        // this.next = 0;
      } else if (boxleft < 0) {
        this.$refs.developyearvalbox.style.left = this.index * 150 + "px";
        // this.next = 1;
      }
    },

    add() {
      this.$nextTick(function () {
        // console.log(this.$refs.developvalchild instanceof Function);
        let number = [];
        let number2 = null;
        let aaa = this.$refs.developvalchild;
        aaa.map((v, i) => {
          number.push(v.offsetHeight);
        });
        number.pop();
        number.map((v, i) => {
          number2 += v;
        });
        this.number2 = number2;
        console.log(number2, number);
      });
    },

    rightClick: function () {
      let boxwidth = parseInt(this.$refs.developyearvalbox.style.width);
      let negativeboxwidth = -(boxwidth - 1200);
      this.index--;
      if (negativeboxwidth >= this.index * 150) {
        this.$refs.developyearvalbox.style.left = -(boxwidth - 1200) + "px";
        this.index = -((boxwidth - 1200) / 150);
        // this.prev = 0;
      } else {
        this.$refs.developyearvalbox.style.left = this.index * 150 + "px";
        // this.prev = 1;
      }
    },

    highlightClick: function (item, ind) {
      this.highlightindex = ind;
      this.dataval = item.val;
      this.datatitle = item.title;
      // setTimeout(() => {
        this.add();
      // }, 300);
    },
    getmentlist() {
      mentlist({
        lang: sessionStorage.getItem("langType"),
      }).then((res) => {
        console.log(res);
        this.index = res.data.length - 8 > 0 ? -(res.data.length - 8) : 0; //点击左右按钮所需下标    (数组长度 - 8) 转为负值
        this.indexmax = res.data.length; //数组长度
        let data = [];
        res.data.map((v, i) => {
          data.push({
            name: v.title,
            year: v.year,
            url: baseURL + v.coverImage,
            newurl: baseURL + v.coverImageChecked,
            backurl: require("../assets/imgs/aboutkangcun/develophighlight.png"),
            title: v.overview,
            val: v.detailListByMonth,
          });
        });
        this.data = data;
        this.highlightindex = res.data.length - 1; //点击高亮
        this.dataval = res.data[res.data.length - 1].detailListByMonth;
        this.datatitle = res.data[res.data.length - 1].overview;
        setTimeout(()=>{
          this.add();
        },300)
       
        // console.log(this.dataval, "wwwwwwwwww");
      });
    },

    developout() {
      this.developoverind = null;
    },
    developover(ind) {
      this.developoverind = ind;
    },
  },

  created() {
    // this.newdata = this.data.slice(0, 8);
  },
};
</script>
<style lang="scss" scoped>
.development {
  width: 1200px;
  min-height: 800px;
  margin-top: 135px;
  .profilejianjiebox {
    width: 100%;
    //height: 87px;
    display: flex;
    align-items: center;
    .profilejianjie {
      width: 238px;
      height: 40px;
      //margin-top: 50px;
    }
    .profilejianjiewenzi {
      width: 160px;
      height: 30px;
      border-left: 1px solid #1e83d1;
      line-height: 30px;
      text-align: center;
      font-size: 24px;
      color: #444444;
      margin-top: 10px;
      margin-left: 22px;
    }
  }
  .developyear {
    width: 100%;
    height: 330px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    .developyearchildline {
      width: 100%;
      height: 4px;
      background: #bfbfbf;
      position: absolute;
      top: 168px;
      left: 0;
    }
    .developyearchildleft {
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-right: 12px solid #bfbfbf;
      border-bottom: 12px solid transparent;
      position: absolute;
      top: 158px;
      left: -12px;
      cursor: pointer;
    }
    .developyearchildleft:hover {
      border-right: 12px solid #037ddc;
    }
    .developyearchildright {
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-left: 12px solid #bfbfbf;
      border-bottom: 12px solid transparent;
      position: absolute;
      top: 158px;
      right: -12px;
      cursor: pointer;
    }
    .developyearchildright:hover {
      border-left: 12px solid #037ddc;
    }
    .developyearval {
      width: 100%;
      height: 225px;
      overflow: hidden;
      position: relative;
      .developyearvalchild {
        height: 225px;
        position: absolute;
        top: 0;
        left: 0;
        transition: left 1s;
        .developyearchild {
          width: 130px;
          height: 225px;
          margin: 0 10px;
          font-size: 20px;
          font-family: 苹方-简;
          color: #444444;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          position: relative;
          cursor: pointer;
          float: left;
          .developyearchildurl {
            width: 30px;
            height: 30px;
            margin-top: 40px;
          }
          .developyearchildair {
            width: 14px;
            height: 14px;
            border: 1px solid #037ddc;
            border-radius: 100%;
            position: absolute;
            top: 110px;
            left: 50%;
            margin-left: -7px;
            background: white;
          }
          .developyearchildname {
            margin-top: 22px;
            width: 100%;
            text-align: center;
          }
          .developyearchildyear {
            width: 100%;
            text-align: center;
            font-size: 38px;
            font-weight: 400;
            color: #037ddc;
            font-family: 圆体-简;
            margin-top: 135px;
          }
          .developyearchildback {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            animation: fade-in 1s 1;
          }
          @keyframes fade-in {
            0% {
              opacity: 0;
            } /*初始状态 透明度为0*/
            100% {
              opacity: 1;
            } /*结束状态 透明度为1*/
          }
          .developyearchildnewurl {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -15px 0 0 -10px;
          }
          .developyearchildnewname {
            font-size: 20px;
            color: white;
            font-family: 苹方-简;
            width: 100%;
            position: absolute;
            top: 60%;
            left: 5%;
            text-align: center;
          }
          .developyearchildnewyear {
            position: absolute;
            bottom: 34%;
            left: 9%;
            font-family: 圆体-简;
            font-size: 38px;
            color: white;
            font-weight: 400;
            width: 118px;
            text-align: center;
          }
        }
      }
    }
  }
  .developval {
    margin-top: 30px;
    width: 100%;
    // min-height: 270px;
    font-family: 苹方-简;
    position: relative;
    .deveurl {
      position: absolute;
      top: 8px;
      left: 80px;
      width: 3px;
      height: calc(100% - 13px);
      // height: calc(100%-20px);
      background: #eeeeee;
    }
    .developvaltop {
      width: 100%;
      height: 80px;
      font-size: 24px;
      color: #444444;
      line-height: 35px;
      margin-bottom: 60px;
    }
    .developvalchild {
      width: 100%;
      // min-height: 100px;
      margin-bottom: 50px;
      font-size: 14px;
      color: #444444;
      position: relative;
      // background: red;
      .deveair {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #bfbfbf;
        position: absolute;
        top: 5px;
        left: 76px;
      }
      .deveairhigh {
        width: 32px;
        height: 32px;
        position: absolute;
        top: -10px;
        left: 66px;
        animation: warn 2s linear infinite;
      }
      @keyframes warn {
        0% {
          transform: scale(0.6);
          opacity: 0;
        }
        25% {
          transform: scale(0.6);
          opacity: 0.8;
        }
        /*50% {*/
        /*transform: scale(0.8);*/
        /*opacity: 0.4;*/
        /*}*/
        /*75% {*/
        /*transform: scale(0.9);*/
        /*opacity: 0.3;*/
        /*}*/
        100% {
          transform: scale(1);
          opacity: 0.1;
        }
      }
      .developtime {
        font-size: 24px;
        position: absolute;
        top: -6px;
        left: 5px;
      }
      .developvalue {
        width: 100%;
        margin-left: 120px;
        color: #585858;
        // white-space: pre;
        // line-height: 30px;
        cursor: pointer;
        font-size: 14px;
        > p {
          line-height: 20px;
          position: relative;
          margin-left: 2em;
          padding-bottom: 10px;
          .inddevelopair {
            position: absolute;
            left: -15px;
            // top: 10px;
            font-size: 30px;
            color: #888;
          }
        }
      }
      .newdevelopvalue {
        color: #020202;
      }
    }
  }
}
</style>