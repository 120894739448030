<template>
  <div class="Aboutheader-wrap">
    <div class="router-list">
      <div
        @click="aboutClick(item)"
        v-for="(item, ind) in data"
        :key="ind"
        :class="{ active: index == ind }"
      >
        {{ item.val }}
      </div>
    </div>
    <Back
      :name="langTrue == 'en-us' ? 'About Kangcun' : '关于康存'"
      backtop="25px"
      backright="40px"
      homeBack="/HomePage"
      :homeName="langTrue == 'en-us' ? 'home page' : '首页'"
    />
  </div>
</template>
 <!-- borderBottom: index === ind ? '2px solid #0072cb' : '2px solid white', -->
<script>
import Back from "@/components/back";
export default {
  name: "AboutKangCunDetailsHeader",
  data() {
    return {
      data: [
        {
          val:
            sessionStorage.getItem("langType") == "en-us"
              ? "Group profile"
              : "集团简介",
          // val: "集团简介",
          index: 0,
        },
        {
          val:
            sessionStorage.getItem("langType") == "en-us"
              ? "Development history"
              : "发展历程",
          index: 1,
        },
        {
          val:
            sessionStorage.getItem("langType") == "en-us"
              ? "Corporate culture"
              : "企业文化",
          index: 2,
        },
        {
          val:
            sessionStorage.getItem("langType") == "en-us"
              ? "Chairman's speech"
              : "董事长致辞",
          index: 3,
        },
      ],
      index: sessionStorage.getItem('aboutIndex')||0,
      langTrue: sessionStorage.getItem("langType"),
    };
  },
  components: {
    Back,
  },
  methods: {
    aboutClick: function (item) {
      sessionStorage.setItem('aboutIndex',item.index);
      this.index = sessionStorage.getItem('aboutIndex');
      console.log(this.index)
      this.$emit("aboutTop", sessionStorage.getItem('aboutIndex'));
    },
  },
};
</script>
<style scoped lang="scss">
.Aboutheader-wrap {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 95px;
  position: absolute;
  top: 662px;
  font-size: 16px;
  color: #444444;
  left: calc(50% - 600px);
  right: calc(50% - 600px);
  .router-list {
    // width: 800px;
    display: flex;
    & > div {
      height: 50px;
      line-height: 50px;
      margin-right: 75px;
      cursor: pointer;
      color: #444;
    }
    & > div:hover,
    & > div.active {
      color: #0072cb;
    }
    & > div::after {
      content: "";
      display: block;
      transition: all 200ms ease-in-out 0s;
      cursor: pointer;
      height: 2px;
      background: #0072cb;
      margin: 0 auto;
      width: 0;
    }
    & > div:hover::after,
    & > div.active::after {
      width: 100%;
    }
  }
}
</style>