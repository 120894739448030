<template>
  <div class="router-list">
    <div class="profile">
      <div class="profilejianjiebox">
        <img class="profilejianjie" :src="jianjieback" />
        <div
          class="profilejianjiewenzi"
          :style="{ color: langTrue == 'en-us' ? '#e7e7e7' : '#444444' }"
        >
          集团简介
        </div>
      </div>
      <img class="profilejianback" :src="data.url" />
      <div class="profilename">{{ data.name }}</div>
      <div class="profileval" v-html="data.val"></div>
      <!-- <div class="profileval" v-for="(item, ind) in data.val" :key="ind">
        {{ item }}
      </div> -->
    </div>
  </div>
</template>

<script>
import { articleList } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
export default {
  name: "BusinessDetails",
  data() {
    return {
      data: {
        name: "",
        val: "",
      },
      jianjieback:
        sessionStorage.getItem("langType") == "en-us"
          ? require("../assets/imgs/aboutkangcun/Groupintroduction.png")
          : require("../assets/imgs/aboutkangcun/jianjie.jpg"),
      // e7e7e7
      langTrue: sessionStorage.getItem("langType"),
    };
  },
  computed: {
    costRequest() {
      return {
        organizationType: this.activeTab,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.getarticleLists();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  mounted() {
    this.getarticleLists();
  },
  methods: {
    getarticleLists() {
      articleList({
        lang: sessionStorage.getItem("langType"),
        title: "康存数科集团关于康存",
      }).then((res) => {
        this.data.name = res.data[0].subTitle;
        this.data.val = res.data[0].content;
        this.data.url = baseURL + res.data[0].coverImage;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  width: 1200px;
  margin-top: 135px;
  .profilejianjiebox {
    width: 100%;
    height: 87px;
    display: flex;
    align-items: center;
    .profilejianjie {
      width: 260px;
      height: 87px;
    }
    .profilejianjiewenzi {
      width: 160px;
      height: 30px;
      border-left: 1px solid #1e83d1;
      line-height: 30px;
      text-align: center;
      font-size: 24px;
      color: #444444;
      margin-top: 60px;
      margin-left: 22px;
    }
  }

  .profilejianback {
    margin-top: 43px;
    width: 1200px;
    height: 530px;
  }
  .profilename {
    font-size: 16px;
    color: #444444;
    margin-top: 38px;
    margin-left: 5px;
    font-family: 苹方-简;
  }
  .profileval {
    font-size: 14px;
    color: #585858;
    margin-top: 20px;
    margin-left: 5px;
    line-height: 45px;
    text-indent: 25px;
    font-family: 苹方-简;
    margin-bottom: 100px;
  }
}
</style>
