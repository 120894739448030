<template>
  <div class="router-wrap">
    <div class="aboutdefails">
      <!-- <img
        class="aboutTop"
        :src="require('../assets/imgs/aboutkangcun/aboutbanner.jpg')"
      /> -->
      <div class="aboutTop"></div>
      <Header @aboutTop="getAboutHeader"></Header>
      <template v-if="topItem == 0">
        <Profile></Profile>
      </template>
      <template v-if="topItem == 1">
        <Development></Development>
      </template>
      <template v-if="topItem == 2">
        <Corporate></Corporate>
      </template>
      <template v-if="topItem == 3">
        <Chairmanspeech></Chairmanspeech>
      </template>
      <Footer></Footer>
    </div>
    <!-- 关于康存详情 -->
  </div>
</template>

<script>
import Header from "@/components/AboutKangCunDetailsHeader";
import Profile from "@/components/AboutGroupProfile.vue";
import Development from "@/components/Aboutdevelopment.vue";
import Corporate from "@/components/Aboutcorporate.vue";
import Chairmanspeech from "@/components/AboutChairmanspeech.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "BusinessDetails",
  data() {
    return {
      topItem: sessionStorage.getItem("aboutIndex") || 0,
    };
  },
  components: {
    Header,
    Profile,
    Development,
    Corporate,
    Chairmanspeech,
    Footer,
  },
  methods: {
    getAboutHeader(data) {
      this.topItem = data;
    },
  },
};
</script>
<style lang="scss" scoped >
.aboutdefails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .aboutTop {
    width: 100%;
    height: 662px;
    background: url("../assets/imgs/aboutkangcun/aboutbanner.jpg") center center;
  }
}
</style>