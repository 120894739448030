<template>
  <div class="router-wrap">
    <div class="corporateCulture">
      <div class="profilejianjiebox">
        <img class="profilejianjie" :src="jianjieback" />
        <div
          class="profilejianjiewenzi"
          :style="{ color: langTrue == 'en-us' ? '#e7e7e7' : '#444444' }"
        >
          企业文化
        </div>
      </div>
      <div class="corporateCultureval">
        <div
          class="corporateCulturevalchild"
          v-for="(item, ind) in data"
          :key="ind"
        >
          <div
            class="corporateCulturevalchildurlbox"
            :style="{ width: datawidth[ind].url }"
          >
            <img
              class="corporateCulturevalchildurl"
              :style="{ width: datawidth[ind].url }"
              :src="item.url"
            />
          </div>

          <div
            class="corporateCulturevalchildval"
            :style="{ width: datawidth[ind].val }"
          >
            <div
              class="corporateCulturevalchildvaleng"
              :style="{ color: langTrue == 'en-us' ? '#6e6e6e' : '#cccccc' }"
            >
              {{ item.english }}
            </div>
            <span
              class="corporateCulturevalchildvalname"
              :style="{ color: langTrue == 'en-us' ? '#cccccc' : '#6e6e6e' }"
              >{{ item.name }}</span
            >
            <div class="corporateCulturevalchildvalval" :title="item.value">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectCorporateCultures } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
export default {
  name: "BusinessDetails",
  data() {
    return {
      langTrue: sessionStorage.getItem("langType"),
      jianjieback:
        sessionStorage.getItem("langType") == "en-us"
          ? require("../assets/imgs/aboutkangcun/corporate.png")
          : require("../assets/imgs/aboutkangcun/corporate.jpg"),
      data: [
        {
          english: "VALUE",
          name: "价值观",
          value: "成人达己，共享共赢",
          url: require("../assets/imgs/aboutkangcun/vision.png"),
        },
      ],
      datawidth: [
        {
          url: "863px",
          val: "312px",
          urlstyle: "left:0px,width:863px",
          valstyle: "right:0px,width:312px",
        },
        {
          url: "795px",
          val: "383px",
          valstyle: "left:0px,width:383px",
          urlstyle: "right:0px,width:795px",
        },
        {
          url: "537px",
          val: "639px",
          urlstyle: "left:0px,width:537px",
          valstyle: "right:0px,width:639px",
        },
        {
          url: "916px",
          val: "260px",
          valstyle: "left:0px,width:260px",
          urlstyle: "right:0px,width:916px",
        },
      ],
    };
  },
  computed: {
    costRequest() {
      return {
        lang: sessionStorage.getItem("langType"),
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.getselectMaterialList();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  mounted() {
    this.getselectMaterialList();
  },
  methods: {
    getselectMaterialList() {
      selectCorporateCultures({
        lang: sessionStorage.getItem("langType"),
      }).then((res) => {
        let data = [];
        res.rows.map((v, i) => {
          data.push({
            english: v.titleEn,
            name: v.title,
            value: v.description,
            url: baseURL + v.coverImage,
          });
        });
        this.data = data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.corporateCulture {
  width: 1200px;
  min-height: 1260px;
  margin-top: 135px;
  .profilejianjiebox {
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    .profilejianjie {
      width: 206px;
      height: 86px;
    }
    .profilejianjiewenzi {
      width: 160px;
      height: 30px;
      border-left: 1px solid #1e83d1;
      line-height: 30px;
      text-align: center;
      font-size: 24px;
      color: #444444;
      margin-top: 60px;
    }
  }
  .corporateCultureval {
    width: 100%;
    min-height: 910px;
    margin-top: 40px;
    .corporateCulturevalchild {
      width: 100%;
      height: 210px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .corporateCulturevalchildurlbox {
        height: 100%;
        position: absolute;
        top: 0;
        overflow: hidden;
      }
      .corporateCulturevalchildurl {
        height: 100%;
        transition: transform 0.75s ease;
      }
      .corporateCulturevalchildurl:hover {
        transform: scale(1.2, 1.2);
      }
      .corporateCulturevalchildval {
        position: absolute;
        top: 0;
        height: 100%;
        color: #6e6e6e;
        font-family: 兰亭黑-简;
        background: #f3f3f3;
        font-size: 38px;
        .corporateCulturevalchildvaleng {
          font-size: 38px;
          color: #cccccc;
          font-weight: 800;
          margin: 25px 0 0 37px;
          font-family: FZZDHJW;
        }
        .corporateCulturevalchildvalname {
          margin: 25px 0 0 37px;
          font-weight: 600;
          padding: 10px 0;
          border-bottom: 2px solid #82b7e0;
        }
        .corporateCulturevalchildvalval {
          margin: 15px 15px 0 37px;
          font-size: 16px;
          padding: 5px 0;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    .corporateCulturevalchild:nth-child(even) {
      .corporateCulturevalchildurlbox {
        right: 0;
      }
      .corporateCulturevalchildval {
        left: 0;
      }
    }
    .corporateCulturevalchild:nth-child(odd) {
      .corporateCulturevalchildurlbox {
        left: 0;
      }
      .corporateCulturevalchildval {
        right: 0;
      }
    }
  }
}
</style>
