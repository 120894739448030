<template>
  <div class="router-wrap">
    <div class="chairmanspeech">
      <div class="profilejianjiebox">
        <img class="profilejianjie" :src="jianjieback" />
        <div
          class="profilejianjiewenzi"
          :style="{ color: langTrue == 'en-us' ? '#e7e7e7' : '#444444' }"
        >
          董事长致辞
        </div>
      </div>
      <img class="chairaboutintroduction" :src="data.aboutintroduction" />
      <img class="chairchairman" :src="data.chairman" />
      <div class="chairmanvalue" v-html="data.value"></div>
    </div>
  </div>
</template>

<script>
import { articleList } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
export default {
  name: "BusinessDetails",
  data() {
    return {
      data: {
        aboutintroduction: "",
        chairman: "",
        value: "",
      },
      langTrue: sessionStorage.getItem("langType"),
      jianjieback:
        sessionStorage.getItem("langType") == "en-us"
          ? require("../assets/imgs/aboutkangcun/messagechairman.png")
          : require("../assets/imgs/aboutkangcun/chairmanspeech.png"),
    };
  },

  computed: {
    costRequest() {
      return {
        organizationType: this.activeTab,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.getarticleList();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  mounted() {
    this.getarticleList();
  },
  methods: {
    getarticleList() {
      articleList({
        lang: sessionStorage.getItem("langType"),
        title: "董事长致辞",
      }).then((res) => {
        console.log(res);
        this.data.value = res.data[0].content;
        this.data.chairman = baseURL + res.data[0].extra3;
        this.data.aboutintroduction = baseURL + res.data[0].coverImage;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chairmanspeech {
  width: 1200px;
  min-height: 1135px;
  margin-top: 135px;

  .profilejianjiebox {
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    .profilejianjie {
      width: 187px;
      height: 96px;
    }
    .profilejianjiewenzi {
      width: 160px;
      height: 30px;
      border-left: 1px solid #1e83d1;
      line-height: 30px;
      text-align: center;
      font-size: 24px;
      color: #444444;
      margin-top: 60px;
    }
  }
  .chairaboutintroduction {
    width: 237px;
    height: 648px;
    margin-top: 120px;
    float: left;
  }
  .chairchairman {
    width: 900px;
    height: 411px;
    margin-left: 63px;
    float: left;
    margin-top: 47px;
  }
  .chairmanvalue {
    width: 900px;
    float: left;
    min-height: 530px;
    margin-top: 30px;
    margin-left: 63px;
    font-size: 14px;
    font-family: 苹方-简;
    color: #585858;
  }
}
</style>
